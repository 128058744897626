
  import { reactive, ref, onMounted } from 'vue'
  import { useStore } from "vuex";
  import { Actions } from "@/store/enums/ConsumerEnums.ts";
  import { useRouter,useRoute } from "vue-router";
  import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
  export default {
    name : 'ConsumerEdit',
    setup(){

      const store = useStore();
      const router = useRouter();
      const route = useRoute();

      const success = ref(false);

      onMounted(() => {
        setCurrentPageBreadcrumbs("Consumers",[
          {title : "Consumers",to : "/consumers/list"},
          {title : "Edit Consumer",to : "/consumers/edit/"+route.params.id}
        ]);

        store.dispatch(Actions.GET_CONSUMER,{id : route.params.id})

      });

      const submitButton = ref<HTMLElement | null>(null);

      const errors = reactive({
        value : {}
      });


      const submitForm = () => {

        if(submitButton.value){
          submitButton.value.setAttribute("data-kt-indicator", "on");
        }

        store.dispatch(Actions.UPDATE_CONSUMER,store.getters.currentConsumer).then((r) => {
          if(r.success){
            errors.value = [];
            success.value = true;
            window.scrollTo(0,0);
            submitButton.value?.removeAttribute("data-kt-indicator");
            setTimeout(() => {
              router.push('/consumers/list')
            },1000)
          }

        }).catch((response) => {
          setTimeout(function(){
            const input = document.getElementsByClassName('is-invalid')[0] as HTMLElement
            input.focus();
          },100)

          errors.value = response.data.errors;

          submitButton.value?.removeAttribute("data-kt-indicator");

        })
      }

      return {
        submitButton,
        submitForm,
        errors,
        store,
        success
      }
    }
  }
